<template>
  <page-section
    class="KpiList"
    :page-tooltip="$t('pages.performance.kpi_list.sidebar_description')"
  >
    <div class="KpiList-Wrapper KpiList-Wrapper--active">
      <div class="is-flex border-b-black m-b-l is-aligned-middle">
        <div class="m-r-a has-text-weight-bold is-size-3">
          {{ $t('pages.performance.kpi_list.active_campaigns') }}
        </div>
        <restricted-button
          permission="kpi.create"
          action-class="button is-black is-uppercase"
          data-testid="add-new-campaign"
          @click="isCreateKpiModalVisible = true"
        >
          {{ $t('pages.performance.kpi_list.add_new_kpis') }}
        </restricted-button>
      </div>
      <transition name="fade">
        <div
          v-if="activeKpiCampaigns.length"
          class="KpiList-List"
        >
          <kpi-campaign
            v-for="campaign in activeKpiCampaigns"
            :key="campaign.id"
            :campaign="campaign"
            @delete:campaign="removeCampaignFromList"
          />
        </div>
        <KpiPlaceholder v-else-if="isLoading" />
        <p v-else class="is-flex is-aligned-middle is-size-4">
          {{ $t('pages.performance.kpi_list.no_active_campaigns') }}
        </p>
      </transition>
    </div>
    <div class="KpiList-Wrapper KpiList-Wrapper--completed">
      <div class="border-b-black m-b-l is-flex is-aligned-middle">
        <div class="has-text-weight-bold is-size-3 m-r-a">
          {{ $t('pages.performance.kpi_list.completed_campaigns') }}
        </div>
        <div>
          {{ $t('pages.performance.kpi_list.activities_completed') }}
        </div>
      </div>
      <transition name="fade">
        <div
          v-if="completeKpiCampaigns.length"
          class="KpiList-List"
        >
          <kpi-campaign
            v-for="campaign in completeKpiCampaigns"
            :key="campaign.id"
            :campaign="campaign"
            @delete:campaign="removeCampaignFromList"
          />
        </div>
        <KpiPlaceholder v-else-if="isLoading" />
        <p v-else class="is-size-4">
          {{ $t('pages.performance.kpi_list.no_completed_campaigns') }}
        </p>
      </transition>
    </div>
    <CreateKpiModal
      :visible.sync="isCreateKpiModalVisible"
    />
    <el-dialog
      :visible.sync="displayNewKpiDialog"
      title=""
      data-testid="initialCreateKpiModal"
    >
      <div class="title is-4 m-b-xl">
        {{ $t('pages.performance.kpi_list.create_first_set') }}
      </div>
      <button
        class="button is-primary"
        style="width: 30%;"
        @click="openCreateModal"
      >
        {{ $t('forms.get_started') }}
      </button>
    </el-dialog>
  </page-section>
</template>

<script>
import CreateKpiModal from '@/components/kpis/CreateKpiModal'
import KpiPlaceholder from '@/components/placeholders/KpiPlaceholder'

const KpiCampaign = () => import(/* webpackChunkName: "KpiCampaign" */ '@/components/kpis/KpiCampaign')

export default {
  components: {
    KpiPlaceholder,
    KpiCampaign,
    CreateKpiModal
  },
  data () {
    return {
      kpiCampaigns: [],
      isLoading: false,
      isCreateKpiModalVisible: false,
      displayNewKpiDialog: false
    }
  },
  computed: {
    activeKpiCampaigns () {
      return this.kpiCampaigns.filter(c => !c.is_completed)
    },
    completeKpiCampaigns () {
      return this.kpiCampaigns.filter(c => c.is_completed)
    }
  },
  watch: {
    activeBrandId () {
      this.fetchKpiCampaigns()
    }
  },
  mounted () {
    this.fetchKpiCampaigns()
  },
  methods: {
    fetchKpiCampaigns () {
      this.isLoading = true
      const payload = {
        params: {
          brands: [this.activeBrandId],
          include: ['markets', 'brand']
        }
      }
      this.$api.get('kpi-campaigns', payload)
        .then(response => {
          this.isLoading = false
          this.kpiCampaigns = response.data.data
          if (!this.kpiCampaigns.length && this.authorizeBool('kpi.create')) {
            this.displayNewKpiDialog = true
          }
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, 'We could not fetch your Kpi Campaigns')
        })
    },
    removeCampaignFromList (campaignId) {
      const campaignIndex = this.kpiCampaigns.findIndex(c => c.id === campaignId)
      this.kpiCampaigns.splice(campaignIndex, 1)
    },
    openCreateModal () {
      this.displayNewKpiDialog = false
      this.isCreateKpiModalVisible = true
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.KpiList-Wrapper {
  margin-bottom: $margin-large;
}
</style>
