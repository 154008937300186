<template>
  <el-dialog
    :visible.sync="isVisible"
    :title="$t('components.performance.kpi_edit.create_kpi_dialog.title')"
    width="50%"
    class="CreateKpiModal"
  >
    <form @submit.prevent="handleForm">
      <div class="CreateKpiModal__default">
        <label class="label">{{ $t('components.performance.kpi_edit.create_kpi_dialog.description_coverage') }}</label>
        <div class="control">
          <label class="radio">
            {{ $t('components.performance.kpi_edit.create_kpi_dialog.all_media_coverage') }}
            <input
              v-model="form.is_default"
              :value="true"
              type="radio"
              name="kpi_brand_default"
            >
          </label>
        </div>
        <div class="control">
          <label class="radio">
            {{ $t('components.performance.kpi_edit.create_kpi_dialog.only_media_coverage_specific_activity') }}
            <input
              v-model="form.is_default"
              :value="false"
              type="radio"
              name="kpi_brand_default"
            >
          </label>
        </div>
      </div>
      <form-field
        :validator="$v.form.name"
        :label="$t('components.performance.kpi_edit.create_kpi_dialog.name_set_kpis')"
        :attribute="$t('forms.kpis_name')"
      >
        <input
          v-model="form.name"
          :placeholder="$t('general.name')"
          class="input"
          type="text"
          name="kpi_name"
          @input="$v.form.name.$touch()"
        >
      </form-field>
      <form-field
        :validator="$v.timeSpanAdded"
        :label="$t('components.performance.kpi_edit.create_kpi_dialog.kpi_time_period')"
        :attribute="$t('forms.time_period')"
      >
        <div class="CreateKpiModal__selected-timespan" @click="$refs.datepicker.showPicker()">
          <div class="values">
            <template v-if="timeSpanAdded">
              <div class="value">
                {{ publicationDate(form.start_date) }}
              </div>
              /
              <div class="value">
                {{ publicationDate(form.end_date) }}
              </div>
            </template>
            <span v-else>{{ $t('forms.select_time_period') }}</span>
          </div>
          <div class="icon">
            <i class="hf hf-calendar" />
          </div>
          <el-date-picker
            ref="datepicker"
            :picker-options="{ onPick: selectTimeSpan, firstDayOfWeek: 1 }"
            range-separator="/"
            type="daterange"
          />
        </div>
      </form-field>
      <div class="has-text-right">
        <button
          :class="{ 'is-loading': form.isLoading }"
          :disabled="form.isLoading"
          class="button is-primary"
          type="submit"
          data-testid="saveNewCampaign"
        >
          Next
        </button>
      </div>
    </form>
  </el-dialog>
</template>

<script>
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'
import { localizedDateFormat } from '@hypefactors/shared/js/utils/dateFormatters'
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

const form = new Form({
  name: { value: '', rules: { required } },
  start_date: { value: '', rules: { required } },
  end_date: { value: '', rules: { required } },
  is_default: { value: false }
})
export default {
  mixins: [PublicationDateMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    form: form.rules(),
    timeSpanAdded: {
      required
    }
  },
  data () {
    return {
      form
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        this.$emit('update:visible', value)
      }
    },
    timeSpanAdded () {
      return this.form.start_date && this.form.end_date
    }
  },
  methods: {
    handleForm () {
      this.$v.$touch()
      if (this.$v.$error) return
      this.form.setPayloadTransformer(payload => {
        payload.brand = this.$store.getters.activeBrandId
        payload.start_date = localizedDateFormat(payload.start_date, 'YYYY-MM-DD')
        payload.end_date = localizedDateFormat(payload.end_date, 'YYYY-MM-DD')
        return payload
      })
      this.form.post('kpi-campaigns')
        .then(response => {
          this.isVisible = false
          this.$notify.success(this.$t('success.kpi_campaign_created'))
          this.form.reset()
          this.$router.push({ name: 'performance.kpi.edit', params: { kpiId: response.data.id } })
        })
        .catch(error => {
          this.$displayRequestError(error)
        })
    },
    selectTimeSpan ({ maxDate, minDate }) {
      if (maxDate && minDate) {
        this.form.start_date = minDate
        this.form.end_date = maxDate
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.CreateKpiModal {
  &__default {
    .control {
      width: 180px;
      background: $grey-lighter;
      margin-bottom: $margin;
      padding: $padding;

      label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__selected-timespan {
    cursor: pointer;
    background: $grey-lighter;
    width: 50%;
    padding: $padding-small;
    display: flex;

    .values {
      flex: 1 1 auto;
      display: flex;
      position: relative;

      .value {
        flex: 1 1 auto;
        text-align: center;
        padding: 0 $padding-small;
      }

    }

    .el-date-editor {
      position: absolute;
      visibility: hidden;
    }
  }
}
</style>
